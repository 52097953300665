import * as React from "react";
import { graphql } from "gatsby";
import type { HeadFC } from "gatsby";
import useCookieState from "../hooks/useCookieState";
import usePageData from "../hooks/usePageData";
import { PropsType } from "../hooks/propsType";
import Seo from "../components/SEO";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Insights from "../components/Insights";
import Hero from "../components/Hero";
import List from "../components/List";
import FQA from "../components/FQA";
import ServicesFeatures from "../components/ServicesFeatures";
import { transformedData } from "../utils/functions";
import "../assets/scss/index.scss";

const Page: React.FC<PropsType> = ({ data, pageContext }) => {
  const [compareList, setCompareList] = useCookieState(
    "compare",
    JSON.stringify([])
  );
  const [openModal, setOpenModal] = React.useState(false);

  const { settings, articles, products, pageSlug } = usePageData(
    data,
    pageContext
  );

  const [list] = React.useState(
    products?.filter(
      (e: any) =>
        `Best ${e?.keyword} in ${e?.city}`
          .toLowerCase()
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .replace(/ /g, "-") === pageSlug
    )[0]
  );

  const [menu] = React.useState(
    transformedData(settings?.header)?.menu?.find(
      (e: any) => e.link === "/" + list.category
    )
  );

  return (
    <div className="tf">
      <Header
        settings={transformedData(settings?.header)}
        settingsFooter={transformedData(settings?.footer)}
        products={products}
        compareList={compareList}
        setCompareList={setCompareList}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <Hero
        settings={{
          title: `Best <span>${list?.keyword}</span> in ${list?.city}`,
          titleSub: "",
          images: transformedData(settings?.metadata)?.images,
        }}
        descriptionShort={false}
        capital={true}
        award={2}
        update={`Last updated: ${new Date(
          new Date().setDate(new Date().getDate() - 1)
        ).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}`}
        number={
          products
            ?.filter(
              (e: any) =>
                `Best ${e?.keyword} in ${e?.city}`
                  .toLowerCase()
                  .replace(/[^a-zA-Z0-9 ]/g, "")
                  .replace(/ /g, "-") === pageSlug
            )[0]
            ?.places?.filter((e: any) => e?.description !== "N/A").length
        }
      />
      <List
        product={products?.filter(
          (e: any) =>
            `Best ${e?.keyword} in ${e?.city}`
              .toLowerCase()
              .replace(/[^a-zA-Z0-9 ]/g, "")
              .replace(/ /g, "-") === pageSlug
        )}
      />
      <FQA
        settings={{
          ...products?.filter(
            (e: any) =>
              `Best ${e?.keyword} in ${e?.city}`
                .toLowerCase()
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .replace(/ /g, "-") === pageSlug
          )[0],
          fqaSubTitle: "FAQ",
          fqaTitle: "Common Asked Questions",
          faqList: products
            ?.filter(
              (e: any) =>
                `Best ${e?.keyword} in ${e?.city}`
                  .toLowerCase()
                  .replace(/[^a-zA-Z0-9 ]/g, "")
                  .replace(/ /g, "-") === pageSlug
            )[0]
            ?.fqa?.map((e: any) => {
              return {
                title: e?.title,
                content: e?.desc,
              };
            }),
        }}
      />
      <Insights
        articles={articles?.slice(0, 3)}
        full={false}
        light={true}
        settings={transformedData(settings?.news)}
      />
      <ServicesFeatures
        dark={false}
        serviceFeatures={{
          seoKeyword: menu?.text,
          subTitle: menu?.titlePage,
          content: menu?.contentCategory,
          title: menu?.titleCategory,
          icon: menu?.icon,
          list: products
            ?.filter((e: any) => "/" + e.category === menu?.link)
            .map((e: any) => {
              return {
                name: e?.keyword,
                slug: `/${`Best ${e?.keyword} in ${e?.city}`
                  .toLowerCase()
                  .replace(/[^a-zA-Z0-9 ]/g, "")
                  .replace(/ /g, "-")}`,
              };
            }),
        }}
      />
      <Footer settings={transformedData(settings?.footer)} />
    </div>
  );
};

export const Head: HeadFC<PropsType> = ({ data, pageContext }) => {
  const { products, pageSlug } = usePageData(data, pageContext);
  const [list, setList] = React.useState(
    products?.filter(
      (e: any) =>
        `Best ${e?.keyword} in ${e?.city}`
          .toLowerCase()
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .replace(/ /g, "-") === pageSlug
    )[0]
  );

  const countPlace = list?.places?.filter(
    (e: any) => e?.description !== "N/A"
  ).length;

  React.useEffect(() => {
    setList(
      products?.filter(
        (e: any) =>
          `Best ${e?.keyword} in ${e?.city}`
            .toLowerCase()
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .replace(/ /g, "-") === pageSlug
      )[0]
    );
  }, [pageContext?.slug]);

  return (
    <Seo
      title={`${countPlace} Best ${list?.city} ${list?.keyword} | SpiritManchester`}
      description={list?.seoDescription}
      url={
        "/" +
        `Best ${list?.keyword} in ${list?.city}`
          .toLowerCase()
          .replace(/[^a-zA-Z0-9 ]/g, "")
          .replace(/ /g, "-") +
        "/"
      }
    />
  );
};

export const query = graphql`
  query {
    allMdx(sort: { frontmatter: { date: DESC } }) {
      nodes {
        ...MdxFields
      }
    }
    allFirestoreData {
      nodes {
        keyword
        city
        display
        category
        description
        fqa {
          desc
          title
        }
        id
        places {
          vicinity
          title
          ratings
          rating
          place_id
          display
          description
          customersSay
          formatted_address
          formatted_phone_number
          photoUrl
          website
        }
        seoDescription
      }
    }
  }
`;

export default Page;
