import * as React from "react";
import star from "../../assets/svg/star.svg";
import star1 from "../../assets/svg/star1.svg";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LanguageIcon from "@mui/icons-material/Language";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import FoundationIcon from "@mui/icons-material/Foundation";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import checked from "../../assets/svg/checked.svg";
import checked1 from "../../assets/svg/checked1.svg";
import "./index.scss";

const questions = {
  q1: {
    q: "What is your name 1",
    r: ["Rest 1", "Rest 2", "Rest 3", "Rest 4"],
  },
  q2: {
    q: "What is your name 2",
    r: ["Rest 12", "Rest 22", "Rest 32", "Rest 42"],
  },
  q3: {
    q: "What is your name 3",
    r: ["Rest 13", "Rest 23", "Rest 33", "Rest 43"],
  },
};

interface ProductProps {
  product: any;
}

const List: React.FC<ProductProps> = ({ product }) => {
  const countPlace = product[0]?.places?.filter(
    (e: any) => e?.description !== "N/A"
  ).length;

  const [modal, setModal] = React.useState(false);
  const [activeQuestion, setActiveQuestion] = React.useState(1);
  const [dataExpand, setDataExpand] = React.useState(
    product[0]?.places?.map((e: any) => 0)
  );
  const [render, setRender] = React.useState(false);

  const handleStartForm = () => {
    // document.body.classList.add("no-scroll");
    // setModal(true);
  };
  const handleCloseForm = () => {
    // document.body.classList.remove("no-scroll");
    // setModal(false);
  };

  React.useEffect(() => {
    setRender(render);
  }, [render]);

  const handleExpandData = (event: any, index: any) => {
    const tempData = dataExpand;
    tempData[index] = 1;
    setDataExpand(tempData);
    setRender(!render);
  };

  const handleLinksTrack = (url: any) => {
    window?.dataLayer.push({
      event: "external_links",
      event_name: "external_links",
      category: "List Places",
      data: url,
    });
  };

  return (
    <>
      {/* <div className={modal ? "form-quote open" : "form-quote"}>
        <CloseIcon className="header-close" onClick={handleCloseForm} />
        <div className="answers-options">
          <h3>{questions["q" + activeQuestion]?.q}</h3>
          <div className="answers-options-item active">
            <img src={checked1} alt="" />
            {questions["q" + activeQuestion]?.r[0]}
          </div>
          <div className="answers-options-item">
            <img src={checked} alt="" />
            {questions["q" + activeQuestion]?.r[1]}
          </div>
          <div className="answers-options-item">
            <img src={checked} alt="" />
            {questions["q" + activeQuestion]?.r[2]}
          </div>
          <div className="answers-options-item">
            <img src={checked} alt="" />
            {questions["q" + activeQuestion]?.r[3]}
          </div>
        </div>
      </div> */}

      <section className="list-data pad0">
        <div className="container">
          <div className="update-date">
            <CalendarMonthIcon />
            {`Updated: ${new Date(
              new Date().setDate(new Date().getDate() - 1)
            ).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}`}
          </div>
          <div className="list-top">
            <div className="list-top-left">
              <h3>Our Recommended Top {countPlace}</h3>
            </div>
            <ul className="list-top-type">
              <li>
                <CheckCircleOutlineIcon /> Licensing
              </li>
              <li>
                <CheckCircleOutlineIcon /> User Reviews
              </li>
              <li>
                <CheckCircleOutlineIcon /> Complains
              </li>
            </ul>
          </div>
          <p className="content-top">{product[0]?.description}</p>
        </div>
      </section>
      {product[0]?.quote && (
        <section className="list-data pad1">
          <div className="container getaquote">
            <p className="get-a-quote">
              Tell us about your project and get help from local{" "}
              {product[0]?.keyword.toLowerCase()}.
            </p>
            <div className="services-high-button">
              <button
                type="button"
                className="orangeButton"
                onClick={handleStartForm}
              >
                Get Quotes Now
              </button>
            </div>
          </div>
        </section>
      )}
      <section className="list-data pad2">
        <div className="container">
          <div className="list-companies">
            {product[0]?.places?.map((e: any, i: any) => {
              if (e?.description === "N/A") return null;
              return (
                <div className="item-company" key={i}>
                  {/* <div className="featured-badge">FEATURED</div> */}
                  {e?.photoUrl && (
                    <div className="company-image-wrapper">
                      <div
                        className="company-image"
                        style={{
                          backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/spirit-92da0.firebasestorage.app/o/places%2F${e?.place_id}.jpg?alt=media)`,
                        }}
                      >
                        <div className="overlay"></div>
                      </div>
                    </div>
                  )}
                  <div className="company-content">
                    <div className="content-top-data">
                      <h2>{e?.title}</h2>
                    </div>
                    {/* <p>
                      Topfed specializes in delivering custom software
                      development solutions to clients worldwide. With over 12
                      years of experience, we have built a reputation of
                      quality, expertise, and the ability to deliver complex
                      projects on time and on budget.
                    </p> */}
                    <div className="content-meta">
                      <ul>
                        {/* <li className="phone">
                          <LocalPhoneIcon /> (201) 778-6697
                        </li> */}
                        {e?.formatted_address && (
                          <li>
                            <LocationOnIcon /> {e?.formatted_address}
                          </li>
                        )}
                        {e?.website && e?.website !== "N/A" && (
                          <li className="website">
                            <LanguageIcon />{" "}
                            <a
                              href={e?.website}
                              target="_blank"
                              rel="nofollow"
                              onClick={() => handleLinksTrack(e?.website)}
                            >
                              Visit Website
                            </a>
                          </li>
                        )}
                        {/* <li className="founded">
                          <FoundationIcon /> Founded 2022
                        </li> */}
                      </ul>
                    </div>
                    <h4>Why choose this provider?</h4>
                    <div className="content-reviews">
                      <div className="item-reviews">
                        <span className="start-list">
                          {[1, 2, 3, 4, 5].map((e, i) => {
                            return (
                              <img
                                src={3 > 0 ? star1 : star}
                                alt="star"
                                width="20"
                                key={i}
                              />
                            );
                          })}
                          <span>
                            <span>{e?.rating}</span> ({e?.ratings})
                          </span>
                        </span>
                      </div>
                    </div>
                    {e?.customersSay && (
                      <div className="content-meta">
                        <ul>
                          <li className="customers">
                            <Diversity3Icon /> Customers say:{" "}
                            <span>{e?.customersSay}</span>
                          </li>
                        </ul>
                      </div>
                    )}
                    <p
                      className={dataExpand[i] ? "showMore" : ""}
                      onClick={(event) => handleExpandData(event, i)}
                    >
                      {e?.description
                        ?.replace(/\*\*\*.*?\*\*\*: /, "")
                        .replace(/\*\*/g, "")}{" "}
                    </p>
                    {/* <div className="company-content-buttons">
                      <div className="company-button email">
                        <PostAddIcon /> Request a Quote
                      </div>
                    </div>
                    <h4>Services</h4>
                    <ul className="content-services">
                      <li>Web Development</li>
                      <li>Mobile Development</li>
                      <li>E-Commerce Websites</li>
                      <li>Website Maintenance</li>
                    </ul>
                    <div className="content-graph">Display Graph</div>
                    <h4>Portfolio and Awards</h4>
                    <div className="portfolio-galery">Gallery</div>
                    <h4>Featured Review</h4>
                    <div className="content-reviews">
                      <div className="item-reviews">
                        <span className="start-list">
                          {[1, 2, 3, 4, 5].map((e, i) => {
                            return (
                              <img
                                src={3 > 0 ? star1 : star}
                                alt="star"
                                width="16"
                                key={i}
                              />
                            );
                          })}
                          <span>5.0</span>
                        </span>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Suspendisse pellentesque elit ex, non convallis
                          dui bibendum ullamcorper. Vivamus sem ipsum,{" "}
                        </p>
                        <div className="review-name">Smith Done</div>
                      </div>
                    </div>
                    <h4>Social Profiles</h4>
                    <div className="social-list">
                      <ul>
                        <li>
                          <a href="#" target="_blank">
                            <img
                              src={`${process.env.PUBLIC_URL}/img/social/facebook.svg`}
                              alt="facebook"
                            />
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <img
                              src={`${process.env.PUBLIC_URL}/img/social/instagram.svg`}
                              alt="instagram"
                            />
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default List;
