import * as React from "react";
import { Link } from "gatsby";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./index.scss";

interface ServicesType {
  serviceFeatures?: any;
  dark: boolean;
  full?: boolean;
}

interface ServiceItemType {
  slug: string;
  name: string;
}

const ServicesFeatures: React.FC<ServicesType> = ({
  serviceFeatures,
  dark = true,
  full = false,
}) => {
  return (
    <section className={dark ? "services-features" : "services-features light"}>
      <div className={full ? "container full" : "container"}>
        <h3>{serviceFeatures?.subTitle}</h3>
        <h2>{serviceFeatures?.title}</h2>
        <div className="services-features-wrap">
          <div className="services-features-item">
            <div className="service-item-head">
              <img
                src={`${process.env.PUBLIC_URL}/img/icons/${serviceFeatures?.icon}.svg`}
                alt={serviceFeatures?.seoKeyword}
              />

              <h4>{serviceFeatures?.seoKeyword}</h4>
            </div>
            <p className="services-features-desc">{serviceFeatures?.content}</p>
            <ul>
              {serviceFeatures?.list?.map((e: ServiceItemType, i: number) => {
                return (
                  <li key={i}>
                    <Link to={e.slug}>
                      {e.name} <ArrowRightAltIcon />
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesFeatures;
